import React from 'react'
import Layout from './../components/layout'
import SEO from './../components/seo'
import StaticLogo from './../components/StaticLogo'
import { Link, graphql } from 'gatsby'
import moment from 'moment'

const Placeholder = props => (<div style={{ backgroundColor: 'black', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexBasis: '100%' }}>
  <StaticLogo display='block' opacity={1} position='static' margin={0} />
</div>)

const Piece = props => {
  const dateFormatted = moment(props.createdAt).format('M/D/YYYY')
  const header = props.headerImage && props.headerImage.image && props.headerImage.image.fluid && props.headerImage.image.fluid.src ? <img src={props.headerImage.image.fluid.src} alt={props.title + ' - header image'} /> : <Placeholder />
  return (
    <article className="blog-piece">
      <Link to={`${props.slug}`} style={{ color: 'white', textDecoration: 'none' }}>
        <div className="img-header">
          {header}
        </div>

        <div>
          <h2 className="title">{props.title}</h2>
          <span className="date">{dateFormatted}</span>
          <p className="summary">{props.summary.summary}</p>
          <span className={`subject ${props.subject.toLowerCase()}`}>{props.subject}</span>
        </div>
      </Link>
    </article>
  )
}

const BlogPage = props => {
  const blogPosts = props.data.allContentfulBlogPost.edges
  return (
    <Layout logoActive={true}>
      <SEO title={`Blog: Berato Wilson, Front End Developer`} />
      <section className="container blog">
        <h1 className="text-center blog-title">Blog</h1>
        <p className="blog-intro text-center">
          This is where I write about code and tech and stuff.
        </p>

        <div className="container blog-posts">
          {blogPosts.map((piece, i) => <Piece key={i} {...piece.node} />)}
        </div>
      </section>
    </Layout>
  )
}

export const blogPostQuery = graphql`
  query {
    allContentfulBlogPost {
    edges {
      node {
        slug
        title
        createdAt
        tags
        subject
        summary {
          summary
        }
        headerImage {
          image {
            fluid(cropFocus: CENTER) {
              src
            }
          }
        }
      }
    }
  }
}
`

export default BlogPage
